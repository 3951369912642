const dev = {
	// API_ENDPOINT_URL: 'https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO'
	API_ENDPOINT_URL: 'http://10.232.0.226:8083/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO',
};

const prod = {
	API_ENDPOINT_URL: 'https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO'
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
