import React from 'react';

import axios from "axios";
import {API_BASE_URL} from "../../configs/AppConfig";
import {notification } from "antd";


export const ApiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 600000
});


export const handlerError = (error) => {
  let notificationParam = { message: 'Ocurrió un error', description: 'Algo no ha funcionado correctamente, revise la operación' };


  if (error.response?.status === 300) {
    notificationParam.message = error.response.data.titulo
    notificationParam.description = error.response.data.descripcion

  }
  if (error.response?.status === 404) {
    notificationParam.message = 'Not Found'
  }

  if (error.response?.status === 500) {
    notificationParam.message = 'Internal Server Error'
  }

  if (error.response?.status === 508) {
    notificationParam.message = 'Time Out'
  }

  notification.error(notificationParam);

  return Promise.reject(error);
};
