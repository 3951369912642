import React, { Component } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import {Menu, Modal, message, Button} from 'antd';
import { connect } from "react-redux";
import {QrReader} from "react-qr-reader";
import ScanService from "../../services/api/scan";
import Utils from "../../utils";

export class NavPanel extends Component {
	state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
	};
	
	render() {
		return (
      <>
        <Menu mode="horizontal">
          <Menu.Item onClick={this.showDrawer}>
            <Button type="primary" shape="circle">
              <div className="justify-content-center">
                <QrcodeOutlined className="nav-icon mr-0" />
              </div>
            </Button>
          </Menu.Item>
        </Menu>
        { this.state.visible ? <Modal
          visible={this.state.visible}
          onOk={false}
          footer={null}
          onCancel={this.onClose}

        >
          <QrReader
            constraints={{facingMode: {exact: "environment"}}}
            onResult={(result, error) => {
              if (!!result) {
                ScanService.scan(Utils.getBedId(result.text)).then(
                  message.success("Código Aceptado")
                )
                this.onClose()
              }
              if (!!error) {
                // console.info(error);
              }
            }}
            style={{width: '100%'}}
          />
        </Modal> : null}
      </>
    );
	}
}

const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);